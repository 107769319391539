import React, { useState } from 'react'
import { Outlet, RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom'
import { useMount } from 'react-use'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ProtectedRoute, Login, ErrorHandler } from './Pages'
import AuthProvider, { useAuth } from './Provider/AuthProvider'
import { FC } from './Services'
import { Flex, Spinner, Background } from './Components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { NewChat } from './Pages/Chat/NewChat'
import { AgencyList } from './Pages/Admin/Agency/List'
import { AgencyEditor } from './Pages/Admin/Agency/Editor'
import { UsersList } from './Pages/Admin/Users/List'
import { UserEditor } from './Pages/Admin/Users/Editor'
import { TravellersList } from './Pages/Admin/Travellers/List'
import { TravellerEditor } from './Pages/Admin/Travellers/Editor'
import { TravellersImport } from './Pages/Admin/Travellers/Import'
import { ProcessesDashboard } from './Pages/Process/Dashboard'
import { ProcessList } from './Pages/Process/List'
import { ProcessStepsVisualizer } from './Pages/Process/Editor'
import { ProcessesErrorStats } from './Pages/Process/ErrorStats'
import { ProcessStepsStats } from './Pages/Process/Stats'
import { ConversationsList } from './Pages/Process/Conversations'
import { GigiForm } from './Pages/Gigi/GigiForm'
import { GigiList } from './Pages/Gigi/GigiList'
import { ConversationPricing } from './Pages/Process/Pricing'
import { cap } from './State'
import { ProcessCompare } from './Pages/Process/Compare'
import { AgencyImport } from './Pages/Admin/Agency/Import'
import { NewForm } from './Pages/Gigi/GigiForm/NewForm'
import { StatisticheOperatori } from './Pages/StatisticheOperatori'
import { ConversationStats } from './Pages/StatisticheOperatori/ConversationStats'
import { AttendancesList } from './Pages/Attendances/List'
import { Attendance } from './Pages/Attendances'
import { ClientsList } from './Pages/Admin/Clients/List'
import { ClientEditor } from './Pages/Admin/Clients/Editor'
import { LineaOccupataList } from './Pages/LineaOccupata/List'
import { LineaOccupata } from './Pages/LineaOccupata'
import { StatisticheAziende } from './Pages/StatisticheAziende'
import { TimeSearch } from './Pages/TimeSearch'
import { StatisticheBlueteam } from './Pages/StatisticheBlueteam'

export const queryClient = new QueryClient()

const LightTheme = React.lazy(() => import('./Themes/Light/Theme'))
const DarkTheme = React.lazy(() => import('./Themes/Dark/Theme'))

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    (d) => ({
      default: d.ReactQueryDevtools
    })
  )
)

const ThemeSelector = ({ children }) => {
  const { themeComponent, themeClassName } = logicLoadTheme()
  return (
    <>
      <React.Suspense fallback={<></>}>
        {themeComponent}
      </React.Suspense>
      <div className={themeClassName}>
        {children}
      </div>
    </>
  )
}

function logicLoadTheme () {
  const [darkMode] = cap.darkMode.useState()
  const { DEFAULT_THEME, SINGLE_THEME } = window._env_
  if (SINGLE_THEME === 'false') {
    return {
      themeComponent: darkMode ? <DarkTheme /> : <LightTheme />,
      themeClassName: `theme-${darkMode ? 'dark' : 'light'}`
    }
    // gestione multipla del tema
  } else {
    // prendo il default con la cartella
    switch (DEFAULT_THEME) {
      case 'light':
        return {
          themeComponent: <LightTheme />,
          themeClassName: 'theme-light'
        }
      case 'dark':
        return {
          themeComponent: <DarkTheme />,
          themeClassName: 'theme-dark'
        }
    }
  }
}

function AppSetup () {
  const { user: storedUser, onLoginJWT } = useAuth()
  const [loading, setLoading] = useState(true)
  const [showDevtools, setShowDevtools] = React.useState(false)

  React.useEffect(() => {
    // @ts-expect-error
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, [])

  useMount(async () => {
    if (FC.authenticated && !storedUser?.accessToken) {
      setLoading(false)
      return true
    }
    storedUser?.accessToken && await onLoginJWT(storedUser?.accessToken)
    setLoading(false)
  })

  return (
    <ThemeSelector>
      <Background whiteBackground>
        <ReactQueryDevtools initialIsOpen={false} />
        {showDevtools && (
          <React.Suspense fallback={null}>
            <ReactQueryDevtoolsProduction />
          </React.Suspense>
        )}
        {loading ? <Flex fw fh><Spinner /></Flex> : <Outlet />}
      </Background>
    </ThemeSelector>
  )
}

const CheckRoute = ({ children, role = [] }) => {
  const { user: userObj = {} } = useAuth()
  const { user = {} } = userObj || {}
  if (role?.length && !role.includes(user?.role)) {
    if (user.role === 'operatoreServizi' || user.role === 'gestoreOperatori') return <Navigate to='/lineaOccupata/list' />
    return <Navigate to='/H24/list/' />
  }
  return children
}

const NavigateRole = () => {
  const { user: userObj = {} } = useAuth()
  const { user = {} } = userObj || {}
  if (user.role === 'gestoreOperatori') return <Navigate to='/lineaOccupata/list' />
  else return <Navigate to='/H24/list/' />
}

const router = createBrowserRouter([
  {
    element: <AuthProvider />,
    errorElement: <ErrorHandler />,
    children: [
      {
        element: <AppSetup />,
        children: [
          {
            element: <ProtectedRoute />,
            children: [
              { path: '*', element: <CheckRoute> <NavigateRole /> </CheckRoute> },
              { path: '/chat/*', element: <CheckRoute role={['admin', 'tester']}> <Navigate to='/chat/new' /> </CheckRoute> },
              { path: '/chat/new', element: <CheckRoute role={['admin', 'tester']}> <NewChat /> </CheckRoute> },
              { path: '/conversations/*', element: <CheckRoute role={['admin']}> <Navigate to='/conversations/list' /> </CheckRoute> },
              { path: '/conversations/list', element: <CheckRoute role={['admin']}><ConversationsList /></CheckRoute> },
              { path: '/conversations/:conversationId', element: <CheckRoute role={['admin']}> <ProcessList /> </CheckRoute> },
              { path: '/pricing/:conversationId', element: <CheckRoute role={['admin']}> <ConversationPricing /> </CheckRoute> },
              { path: '/admin/operatore/:conversationId', element: (<CheckRoute role={['admin']}> <ConversationStats /> </CheckRoute>) },
              { path: '/gattinoniWA/*', element: <CheckRoute role={['admin', 'operatoreH24', 'gestoreOperatori', 'tester', 'gestoreH24']}> <Navigate to='/gattinoniWA/list' /> </CheckRoute> },
              { path: '/gattinoniWA/list/', element: <CheckRoute role={['admin', 'operatoreH24', 'gestoreOperatori', 'tester', 'gestoreH24']}><GigiList allowSendMessage onlyGattinoni /> </CheckRoute> },
              { path: '/H24WA/*', element: <CheckRoute role={['admin', 'operatoreH24', 'gestoreOperatori', 'tester', 'gestoreH24']}> <Navigate to='/H24WA/list' /> </CheckRoute> },
              { path: '/H24WA/list/', element: <CheckRoute role={['admin', 'operatoreH24', 'gestoreOperatori', 'tester', 'gestoreH24']}><GigiList noGattinoni /> </CheckRoute> },
              { path: '/H24/*', element: <CheckRoute role={['admin', 'operatoreH24', 'gestoreOperatori', 'tester', 'gestoreH24']}> <Navigate to='/H24/list' /> </CheckRoute> },
              { path: '/H24/list/', element: <CheckRoute role={['admin', 'operatoreH24', 'gestoreOperatori', 'tester', 'gestoreH24']}><GigiList noWhatsapp allowCreate customAssignedFilter /> </CheckRoute> },
              { path: '/attendances/*', element: <CheckRoute role={['admin', 'operatoreH24', 'gestoreOperatori', 'tester', 'gestoreH24']}> <Navigate to='/attendances/list' /> </CheckRoute> },
              { path: '/attendances/list/', element: <CheckRoute role={['admin', 'operatoreH24', 'gestoreOperatori', 'tester', 'gestoreH24']}><AttendancesList /> </CheckRoute> },
              { path: '/attendances/:attendanceId', element: <CheckRoute role={['admin', 'operatoreH24', 'gestoreOperatori', 'tester', 'gestoreH24']}><Attendance /> </CheckRoute> },
              { path: '/operatore/new', element: <CheckRoute role={['admin', 'operatoreH24', 'gestoreOperatori', 'tester', 'gestoreH24']}><NewForm /> </CheckRoute> },
              { path: '/operatore/:conversationId', element: <CheckRoute role={['admin', 'operatoreH24', 'gestoreOperatori', 'tester', 'gestoreH24']}><GigiForm /> </CheckRoute> },
              { path: '/lineaoccupata/*', element: <CheckRoute role={['admin', 'operatoreH24', 'gestoreOperatori', 'operatoreServizi', 'tester', 'gestoreH24']}><Navigate to='/lineaoccupata/list' /> </CheckRoute> },
              { path: '/lineaoccupata/list', element: <CheckRoute role={['admin', 'operatoreH24', 'gestoreOperatori', 'operatoreServizi', 'tester', 'gestoreH24']}><LineaOccupataList /> </CheckRoute> },
              { path: '/lineaoccupata/:lineaOccupataId', element: <CheckRoute role={['admin', 'operatoreH24', 'gestoreOperatori', 'operatoreServizi', 'tester', 'gestoreH24']}><LineaOccupata /> </CheckRoute> },
              { path: '/admin/*', element: <CheckRoute role={['admin']}> <Navigate to='/admin/agency/list' /> </CheckRoute> },
              { path: '/admin/agency/list', element: <CheckRoute role={['admin', 'gestoreH24', 'gestoreOperatori']}> <AgencyList /> </CheckRoute> },
              { path: '/admin/agency/:agencyId', element: <CheckRoute role={['admin', 'gestoreH24', 'gestoreOperatori']}> <AgencyEditor /> </CheckRoute> },
              { path: '/admin/agency/import', element: <CheckRoute role={['admin', 'gestoreH24', 'gestoreOperatori']}> <AgencyImport /> </CheckRoute> },
              { path: '/admin/clients/list', element: <CheckRoute role={['admin', 'gestoreH24']}> <ClientsList /> </CheckRoute> },
              { path: '/admin/clients/new', element: <CheckRoute role={['admin', 'gestoreH24']}> <ClientEditor /> </CheckRoute> },
              { path: '/admin/clients/:clientId', element: <CheckRoute role={['admin', 'gestoreH24']}> <ClientEditor /> </CheckRoute> },
              { path: '/admin/users/list', element: <CheckRoute role={['admin', 'gestoreH24']}> <UsersList /> </CheckRoute> },
              { path: '/admin/users/:userId', element: <CheckRoute role={['admin', 'gestoreH24']}> <UserEditor /> </CheckRoute> },
              { path: '/admin/travellers/list', element: <CheckRoute role={['admin', 'gestoreH24']}> <TravellersList /> </CheckRoute> },
              { path: '/admin/travellers/import', element: <CheckRoute role={['admin', 'gestoreH24']}> <TravellersImport /> </CheckRoute> },
              { path: '/admin/travellers/:travellerId', element: <CheckRoute role={['admin', 'gestoreH24']}> <TravellerEditor /> </CheckRoute> },
              { path: '/process/*', element: <CheckRoute role={['admin']}> <Navigate to='/process/dashboard' /> </CheckRoute> },
              { path: '/process/dashboard', element: <CheckRoute role={['admin']}> <ProcessesDashboard /> </CheckRoute> },
              { path: '/process/errorStats', element: <CheckRoute role={['admin']}> <ProcessesErrorStats /> </CheckRoute> },
              { path: '/process/:processId', element: <CheckRoute role={['admin']}> <ProcessStepsVisualizer /> </CheckRoute> },
              { path: '/process/stats/:processId', element: <CheckRoute role={['admin']}> <ProcessStepsStats /></CheckRoute> },
              { path: '/process/compare/:processId', element: <CheckRoute role={['admin']}> <ProcessCompare /></CheckRoute> },
              { path: '/dashboard/statistiche/operatori', element: <CheckRoute role={['admin', 'gestoreOperatori', 'gestoreH24']}> <StatisticheOperatori /> </CheckRoute> },
              { path: '/timeSearch', element: <CheckRoute role={['admin', 'gestoreOperatori', 'gestoreH24']}> <TimeSearch /> </CheckRoute> },
              { path: '/dashboard/statistiche/aziende', element: <CheckRoute role={['admin', 'gestoreH24']}> <StatisticheAziende /> </CheckRoute> },
              { path: '/dashboard/statistiche/blueteam', element: <CheckRoute role={['admin', 'gestoreH24']}> <StatisticheBlueteam /> </CheckRoute> }
            ]
          },
          { path: '/login', element: <Login /> }
        ]
      }
    ]
  }
])
function App () {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  )
}
export default App
